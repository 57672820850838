import axios from "axios";
import { Question } from '@/types/question'
import { QuestionUserView } from "@/types/question_user_view"

export class QuestionRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = QuestionRepository.DOMAIN + "v1/projects/" + this.projectId + "/questions/";
  }

  async getList(params: { [key: string]: string | number }): Promise<Question[]> {
    return axios.get(this.url, { params: params }).then((response) => {
      return response.data.questions;
    });
  }
  async get(question_id: number): Promise<Question> {
    return axios.get(this.url + question_id).then((response) => {
      return response.data;
    });
  }

  async viewUsers(question_id: number): Promise<QuestionUserView[]> {
    return axios.get(this.url + question_id + '/view_users').then((response) => {
      return response.data.users;
    });
  }
  // async update(question_id: number, body: string, answer: string, question_category_id?: number): Promise<Question> {
  async update(question_id: number, params: any, files: any[], delete_file_ids: number[]): Promise<Question> {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }
    const question = JSON.stringify(params)
    formData.append('question', question)
    if (delete_file_ids) {
      const delete_ids = JSON.stringify(delete_file_ids)
      formData.append('delete_question_file_ids', delete_ids)

    }
    formData.append('project_id', String(this.projectId))



    return axios
      .put(this.url + question_id, formData, config).then((response) => {
        return response.data
      })
  }
  async add(body: string, answer: string, files: any[], question_category_id: number): Promise<Question> {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const params = {
      body: body,
      answer: answer,
      question_category_id: question_category_id
    }
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }
    const question = JSON.stringify(params)
    formData.append('question', question)
    formData.append('project_id', String(this.projectId))

    return axios.post(this.url, formData, config).then((response) => {
      return response.data;

    })
  }
  async bulkAdd(data: { body: string, answer: string, question_category_id: number }[]): Promise<Question[]> {
    return axios.post(this.url + "bulk_create", {
      project_id: this.projectId,
      data: data,
    }).then((response) => {
      return response.data;
    });
  }
  async delete(question_id: number): Promise<void> {
    return axios.delete(this.url + question_id)

  }
  async iine(question_id: number): Promise<Question> {
    return axios.post(this.url + question_id + "/iine").then((response) => {
      return response.data
    })
  }
  async cancelIine(question_id: number): Promise<Question> {
    return axios.post(this.url + question_id + "/cancel_iine").then((response) => {
      return response.data
    })
  }
}
