import { ref, computed } from "vue";
import { QusitionState } from "@/state/QusitionState";
import { Question } from "@/types/question"
import { QuestionUserView } from "@/types/question_user_view"
import { QuestionRepository } from '@/repositories/QuestionRepository'
import { QuestionHistoryRepository } from '@/repositories/QuestionHistoryRepository'
import { QuestionHistory } from "@/types/question_history";
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'
export const QuestionDetailLogic = () => {

    const detailCommentLoading = ref(false)
    const detailIineLoading = ref(false)
    const detailQuestionLoading = ref(false)
    const detailAddQuestionLoading = ref(false)

    const user_views = ref<QuestionUserView[]>([])
    // 全部入りは一緒にしたいよなぁ・・・
    const detailQuestionAllLoading = computed(() => detailCommentLoading.value || detailIineLoading.value || detailQuestionLoading.value || detailAddQuestionLoading.value);
    const loadQuestion = async (question_id: number) => {
        // detailQuestion.value = null;
        // QusitionState.state.question = null;
        detailQuestionLoading.value = true;
        QusitionState.set(null);
        const repository: QuestionRepository = new QuestionRepository(QusitionState.state.projectId);
        try {
            const question: Question = await repository.get(question_id);
            QusitionState.set(question);
            detailQuestionLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。<br>再読み込みしてお試しください");
        }

    };
    const loadViewUsers = async (question_id: number) => {
        detailQuestionLoading.value = true;
        const repository: QuestionRepository = new QuestionRepository(QusitionState.state.projectId);
        try {
            user_views.value = await repository.viewUsers(question_id);
            detailQuestionLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。<br>再読み込みしてお試しください");
        }

    }
    const resetQuestion = () => {
        QusitionState.set(null);

    }
    const updateQuestion = async (question_id: number, body: string, answer: string, files: any[], delete_file_ids: number[]) => {
        // 表示されたものと違う場合は何もしない
        detailQuestionLoading.value = true;
        const repository: QuestionRepository = new QuestionRepository(QusitionState.state.projectId);
        try {
            // const question: Question = await repository.update(question_id, formData);
            const question: Question = await repository.update(question_id, { body: body, answer: answer }, files, delete_file_ids);
            QusitionState.update(question, true)
            detailQuestionLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }

    };
    const moveQuestionCategory = async (question_id: number, question_category_id: number) => {
        detailQuestionLoading.value = true;
        const repository: QuestionRepository = new QuestionRepository(QusitionState.state.projectId);
        try {
            const question: Question = await repository.update(question_id, { question_category_id: question_category_id }, [], []);
            QusitionState.update(question, true)
            detailQuestionLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, '更新に失敗しました。再読み込みをしてお試しください')
        }
    };
    const iine = async (question_id: number) => {
        detailIineLoading.value = true

        const repository: QuestionRepository = new QuestionRepository(QusitionState.state.projectId);
        try {
            const question: Question = await repository.iine(question_id);
            QusitionState.update(question, true)
            detailIineLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, 'いいねに失敗しました。再読み込みをしてお試しください')
        }

    };
    const cancelIine = async (question_id: number) => {
        detailIineLoading.value = true
        const repository: QuestionRepository = new QuestionRepository(QusitionState.state.projectId);
        try {
            const question: Question = await repository.cancelIine(question_id);
            QusitionState.update(question, true)
            detailIineLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, 'いいねキャンセルに失敗しました。再読み込みをしてお試しください')
        }

    };
    const sendComment = async (question_id: number, body: string) => {
        detailCommentLoading.value = true;
        const repository: QuestionHistoryRepository = new QuestionHistoryRepository(QusitionState.state.projectId);
        try {
            const history: QuestionHistory = await repository.sendComment(question_id, body);
            QusitionState.addHistory(question_id, history);
            detailCommentLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, 'コメント送信に失敗しました。再読み込みをしてお試しください')

        }

    };
    const updateComment = async (comment_id: number, body: string) => {
        detailCommentLoading.value = true;
        const repository: QuestionHistoryRepository = new QuestionHistoryRepository(QusitionState.state.projectId);
        try {
            const history: QuestionHistory = await repository.updateComment(comment_id, body)
            QusitionState.updateHistory(history)
            detailCommentLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, '更新に失敗しました。再読み込みをしてお試しください')

        }

    }
    const deleteComment = async (question_id: number, comment_id: number) => {
        detailCommentLoading.value = true;
        const repository: QuestionHistoryRepository = new QuestionHistoryRepository(QusitionState.state.projectId);
        try {
            await repository.deleteComment(comment_id)
            QusitionState.removeHistory(question_id, comment_id);
            detailCommentLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, 'コメント削除に失敗しました。再読み込みをしてお試しください')

        }

    };
    const addQuestion = async (categoryId: number, body: string, answer: string, files: any[]) => {
        detailAddQuestionLoading.value = true;
        // const question: Question = { body: body, answer: answer, question_category_id: QusitionState.state.question_category_id }
        const repository: QuestionRepository = new QuestionRepository(QusitionState.state.projectId);
        try {
            const question: Question = await repository.add(body, answer, files, categoryId);
            if (categoryId === QusitionState.state.questionCategoryId) {
              QusitionState.add(question)
            }
            detailAddQuestionLoading.value = false

        } catch (e: any) {
            useExceptionMessage(e, '作成に失敗しました。再読み込みをしてお試しください')
        }

    };

    const deleteQuestion = async (question_id: number) => {
        detailQuestionLoading.value = true;
        const repository: QuestionRepository = new QuestionRepository(QusitionState.state.projectId);
        try {
            await repository.delete(question_id)
            QusitionState.remove(question_id);
            detailQuestionLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, '作成に失敗しました。再読み込みをしてお試しください')
        }

    };
    const detailQuestionStateReturn = () => {
        return {
            addQuestion,
            deleteQuestion,
            loadQuestion,
            resetQuestion,
            updateQuestion,
            moveQuestionCategory,
            detailQuestionAllLoading,
            detailCommentLoading,
            detailIineLoading,
            detailQuestionLoading,
            detailAddQuestionLoading,
            iine,
            cancelIine,
            sendComment,
            updateComment,
            deleteComment,
            user_views,
            loadViewUsers
        }
    }
    return {
        detailQuestionStateReturn,
        ...detailQuestionStateReturn()
    }
}



