import axios from "axios";
import { QuestionHistory } from '@/types/question_history'

export class QuestionHistoryRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = QuestionHistoryRepository.DOMAIN + "v1/projects/" + this.projectId + "/question_histories/"
   }
  async sendComment(question_id: number, body: string): Promise<QuestionHistory> {
    return axios.post(this.url + "comment", 
    { comment: { content: { comment: body } }, project_id: this.projectId, question_id: question_id }).then((response) => {
      return response.data
    })
  }
  async updateComment(comment_id: number, body: string): Promise<QuestionHistory> {
    return axios.put(this.url + comment_id + "/update_comment", { comment: { content: { comment: body } } }).then((response) => {
      return response.data
    })
  }
  async deleteComment(comment_id: number): Promise<void> {
    return axios.delete(this.url + comment_id + "/destroy_comment")
  }
}
