import { ref, watch } from "vue";
import { ElMessage } from "element-plus"
import axios from 'axios';
import { QusitionState } from "@/state/QusitionState";
import { QuestionFavoriteRepository } from '@/repositories/QuestionFavoriteRepository'
export const QuestionFavoriteLogic = () => {

    const questionFavoriteErrors = ref("");
    const questionFavoriteLoading = ref(false)

    const favorite = async (question_id: number) => {
        questionFavoriteLoading.value = true

        const repository: QuestionFavoriteRepository = new QuestionFavoriteRepository(QusitionState.state.projectId);
        try {
            await repository.favorite(question_id);
            QusitionState.setQuestionFavorite(question_id, true)
            questionFavoriteLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                questionFavoriteErrors.value = `お気に入りに失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }

    };
    const cancelFavorite = async (question_id: number) => {
        questionFavoriteLoading.value = true
        const repository: QuestionFavoriteRepository = new QuestionFavoriteRepository(QusitionState.state.projectId);
        try {
            await repository.cancelFavorite(question_id);
            QusitionState.setQuestionFavorite(question_id, false)
            questionFavoriteLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                questionFavoriteErrors.value = `お気に入りのキャンセルに失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }

    };
    const questionFavoriteStateReturn = () => {
        return {
            favorite,
            cancelFavorite,
            questionFavoriteErrors,
            questionFavoriteLoading
        }
    }
    watch(questionFavoriteErrors, () => {
        if (questionFavoriteErrors.value == "") {
            return;
        }
        ElMessage({
            showClose: true,
            message: questionFavoriteErrors.value,
            type: 'error'
        });
        questionFavoriteErrors.value = "";
    })
    return {
        questionFavoriteStateReturn,
        ...questionFavoriteStateReturn()
    }
}


