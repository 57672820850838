import axios from "axios";

export class QuestionFavoriteRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) { 
    this.url = QuestionFavoriteRepository.DOMAIN + "v1/projects/" + projectId +"/question_favorites/"
  }


  async favorite(question_id: number): Promise<void> {
    return axios.post(this.url, { question_id: question_id, project_id: this.projectId });
  }
  async cancelFavorite(question_id: number): Promise<void> {
    return axios.delete(this.url + "cancel", { params: { project_id: this.projectId, question_id: question_id } });
  }
}
